import { Component, OnInit } from '@angular/core';
import { RestService } from '../rest.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  jobsPost:any = [];
 constructor(public rest:RestService, private route: ActivatedRoute, private router: Router) { }

 ngOnInit() {
    this.getJoblist();
  }


  getJoblist() {
    this.jobsPost = [];
    this.rest.getJoblist().subscribe((data: {}) => {
      console.log(data);
      this.jobsPost = data;
      // console.log(data)
    });
  }
}
