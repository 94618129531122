<!-- <h2>jobs List</h2>

<div>
  <button (click)="add()">
    Add
  </button>
</div>

<ul class="products">
  <li *ngFor="let p of jobsPost; let i=index;">
    <a routerLink="/product-details/{{p._id}}">
      <span class="badge">{{i+1}}</span> {{p.dname}}
    </a>
        <a routerLink="/product-details/{{p._id}}">
      <span class="badge">{{i+1}}</span> {{p.dgname}}
    </a>

    <button class="delete" title="delete product"
      (click)="delete(p._id)">x</button>
  </li>
</ul> -->

<mat-card class="example-card">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title> Gunadhya Software</mat-card-title>
</mat-card-header>
</mat-card>


<br>
<mat-card class="cwidth" *ngFor="let p of jobsPost; let i=index;">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title>{{ p.dname }}</mat-card-title>
    <mat-card-subtitle>{{ p.rname }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <p>
    {{ p.j_desc}}
    </p>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button>LIKE</button>
    <button mat-button>SHARE</button>
  </mat-card-actions>
</mat-card>
		


 

